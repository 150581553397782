/* website: 3794-lexusofsaintjohn1
 * created at 2022-08-30 4:00 by Rymma
 */

// Import all makes and organization styles files
@import "../templates/makes/lexus.scss";
@import "../utils/icons.scss";

@include breakpoint(mobile){
    .widget-sr {
        &.dealer__saint-john-lexus {
          .sr-modal__wrapper{
              margin-top: 50px;
          }
          .sr-modal--white-close{
            margin-top: 100px;
          }
        }
    }
}